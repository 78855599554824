var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"regular-tables","fluid":"","tag":"section"}},[_c('v-card',[_c('v-card-title',[_vm._v(" الفواتير "),_c('v-spacer'),_c('v-spacer'),(_vm.permissions.add)?_c('router-link',{attrs:{"to":{ path: '/billsForm'},"color":"blue"}},[_c('v-btn',{staticClass:"mx-2",attrs:{"color":"blue"}},[_vm._v(" اضافة فاتورة + ")])],1):_vm._e()],1),_c('v-data-table',{staticClass:"custom_table_class mt-8",attrs:{"loading":_vm.dataLoading,"headers":_vm.headers,"search":_vm.search,"items":_vm.items,"items-per-page":20,"footer-props":{
        'items-per-page-options': [5, 10, 20, 40, 50],
      },"options":_vm.options,"server-items-length":_vm.total,"page-count":_vm.numberOfPages},on:{"update:options":function($event){_vm.options=$event},"fetchAllItems":_vm.fetchAllItems},scopedSlots:_vm._u([{key:"item.color_e",fn:function(ref){
      var item = ref.item;
return [_c('p',{staticClass:"color_primary"},[_vm._v(" "+_vm._s(item.color_e)+" ")])]}},(_vm.permissions.update || _vm.permissions.read || _vm.permissions.remove)?{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"medium":"","color":"primary"}},'v-icon',attrs,false),on),[_vm._v(" mdi-dots-vertical ")])]}}],null,true)},[_c('v-list',[(_vm.permissions.update || _vm.permissions.read)?_c('v-list-item',{attrs:{"to":'/billsForm/' + item.id}},[_c('v-icon',{staticClass:"ml-2"},[_vm._v(" mdi-pencil ")]),_vm._v(" تعديل ")],1):_vm._e(),(_vm.permissions.remove)?_c('v-list-item',{attrs:{"color":"primary"}},[_c('v-icon',{staticClass:"ml-2",attrs:{"color":"danger"}},[_vm._v(" mdi-trash-can-outline ")]),_c('span',{staticClass:"color_danger"},[_vm._v(" حذف ")])],1):_vm._e(),_c('v-list-item',{on:{"click":function($event){return _vm.generateReport(item.id)}}},[_c('v-icon',{staticClass:"ml-2"},[_vm._v(" mdi-printer ")]),_c('span',[_vm._v("طباعة ")])],1)],1)],1)],1)]}}:null],null,true)}),[_c('div',[_c('vue-html2pdf',{ref:"html2Pdf",attrs:{"show-layout":false,"float-layout":true,"enable-download":true,"preview-modal":false,"paginate-elements-by-height":1400,"filename":"فاتورة","pdf-quality":2,"manual-pagination":true,"pdf-format":"a4","pdf-orientation":"portrait","pdf-content-width":"100%"},on:{"progress":function($event){return _vm.onProgressPdf($event)}}},[_c('pdf-content',{attrs:{"slot":"pdf-content","pdf-data":_vm.pdfData},slot:"pdf-content"})],1),_c('custom-progress',{directives:[{name:"show",rawName:"v-show",value:(_vm.showProgress),expression:"showProgress"}],attrs:{"progress":_vm.progressNumber}})],1)]],2),_c('v-snackbar',{attrs:{"color":"success","shaped":"","bottom":"","left":"","timeout":_vm.timeout},model:{value:(_vm.successSnackbar),callback:function ($$v) {_vm.successSnackbar=$$v},expression:"successSnackbar"}},[_vm._v(" "+_vm._s(_vm.successMessage)+" ")]),_c('v-snackbar',{attrs:{"color":"red","shaped":"","bottom":"","left":"","timeout":_vm.timeout},model:{value:(_vm.errorSnackbar),callback:function ($$v) {_vm.errorSnackbar=$$v},expression:"errorSnackbar"}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }